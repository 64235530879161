import { Icon, Typography } from '@matillion/component-library'
import { useGetActivity } from 'api/hooks/useGetActivity'
import { CellTypography } from 'components/CellTypography'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { DateTime } from 'components/DateTime'
import ErrorMessage from 'components/ErrorMessage'
import { InfiniteScrollAnchor } from 'components/InfiniteScrollAnchor'
import { Cell, Row, TBody, THead, Table } from 'components/DataTable'
import { ResultsMessage } from 'components/ResultsMessage'
import { FC } from 'react'
import { PipelineTriggerType } from 'types/eos'
import { useStatusTranslation } from '../../../hooks/useStatusTranslation'
import { ApplicationLink } from './ApplicationLink/ApplicationLink'
import { FilterCellHead } from './FilterCellHead/FilterCellHead'
import classes from './RunHistoryDataGrid.module.scss'
import StatusBadge from './StatusBadge/StatusBadge'
import { calcDuration } from './hooks/calcDuration'
import { formatTrigger } from './hooks/formatTrigger'
import DropdownMenu from './Dropdown/DropdownMenu'

const RunSourceLookup = {
  ORCHESTRATION: 'Orchestration',
  TRANSFORMATION: 'Transformation',
  BATCH_LOAD: 'Batch'
}

const RunSourceSubtextLookup = {
  DPC_ETL: 'Designer',
  METL: 'Matillion ETL',
  MDL_BATCH: 'Data Loader'
}

const TriggerIconLookup: Partial<Record<PipelineTriggerType, FC>> = {
  HUB_USER: Icon.Person,
  DPC_SCHEDULE: Icon.Schedule,
  API_USER: Icon.Laptop
}

const RunHistoryDataGrid = () => {
  const {
    activity,
    fetchNextPage,
    isFetching,
    isRefreshing,
    hasEarlier,
    isError
  } = useGetActivity()
  const rows = activity && !isRefreshing ? activity : []
  const noResults = !activity?.length && !isFetching
  const endOfResults = !hasEarlier && !isFetching && !noResults
  const translateStatus = useStatusTranslation()

  return (
    <>
      <Table>
        <THead sticky>
          <Row className={classes.RunHistoryDataGrid__Row}>
            <Cell className={classes.RunHistoryDataGrid__Cell} semantics="head">
              Pipeline
            </Cell>
            <Cell className={classes.RunHistoryDataGrid__Cell} semantics="head">
              Source
            </Cell>
            <Cell className={classes.RunHistoryDataGrid__Cell} semantics="head">
              <Typography weight="bold" format="bcs">
                Project
              </Typography>
            </Cell>
            <FilterCellHead
              className={classes.RunHistoryDataGrid__Cell}
              filterKey="status"
              renderCheckboxContent={translateStatus}
            >
              Status
            </FilterCellHead>
            <Cell className={classes.RunHistoryDataGrid__Cell} semantics="head">
              Environment
            </Cell>
            <Cell className={classes.RunHistoryDataGrid__Cell} semantics="head">
              Started at / by
            </Cell>
            <Cell className={classes.RunHistoryDataGrid__Cell} semantics="head">
              Ended at / duration
            </Cell>
            <Cell className={classes.RunHistoryDataGrid__Cell} semantics="head">
              <span className="u-visually-hidden">External links</span>
            </Cell>
          </Row>
        </THead>
        <TBody>
          {rows.map(({ id, definition, _links, result, context, trigger }) => (
            <Row className={classes.RunHistoryDataGrid__Row} key={id}>
              <Cell className={classes.RunHistoryDataGrid__Cell}>
                {/* Pipeline */}
                <CellTypography
                  text={definition.pipelineName}
                  testId="runhistory.table.pipeline-name"
                  pipelineId={id}
                  isLink={!!_links.steps}
                />
              </Cell>
              <Cell className={classes.RunHistoryDataGrid__Cell} noWrap>
                {/* Source */}
                <CellTypography
                  text={RunSourceLookup[definition.pipelineKind]}
                  testId="runhistory.table.source"
                  subtextTestId="runhistory.table.source.subtext"
                  subtext={RunSourceSubtextLookup[definition.type]}
                />
              </Cell>
              <Cell className={classes.RunHistoryDataGrid__Cell}>
                {/* Project / branch */}
                <CellTypography
                  text={
                    'projectName' in definition && definition.projectName
                      ? definition.projectName
                      : '-'
                  }
                />
              </Cell>
              <Cell className={classes.RunHistoryDataGrid__Cell} noWrap>
                {/* Status */}
                <StatusBadge status={result.status} />
              </Cell>
              <Cell className={classes.RunHistoryDataGrid__Cell}>
                {/* Environment */}
                <CellTypography
                  testId="runhistory.table.environment"
                  text={
                    context.type === 'METL_INSTALLATION'
                      ? context.installationName
                      : context.environment
                  }
                />
              </Cell>
              <Cell className={classes.RunHistoryDataGrid__Cell} noWrap>
                {/* Started at / by */}
                <CellTypography
                  text={<DateTime timestamp={result.startedAt} />}
                  subtext={formatTrigger(trigger)}
                  SubtextIcon={TriggerIconLookup[trigger.type]}
                />
              </Cell>
              <Cell className={classes.RunHistoryDataGrid__Cell} noWrap>
                {/* Ended at / duration */}
                <CellTypography
                  text={<DateTime timestamp={result.finishedAt} />}
                  subtext={calcDuration(
                    result.startedAt,
                    result.finishedAt ?? ''
                  )}
                />
              </Cell>
              <Cell className={classes.RunHistoryDataGrid__Cell}>
                {result.status === 'CANCELLING' ||
                result.status === 'RUNNING' ? (
                  <DropdownMenu links={_links} pipelineId={id} />
                ) : (
                  <ApplicationLink
                    links={_links}
                    label={
                      definition.type === 'DPC_ETL'
                        ? 'Designer'
                        : 'Matillion Data Loader'
                    }
                  />
                )}
              </Cell>
            </Row>
          ))}
        </TBody>
      </Table>
      <InfiniteScrollAnchor onIntersect={fetchNextPage} />
      <div className={classes.Spinner}>{isFetching && <CenteredSpinner />}</div>
      {endOfResults && !isError && (
        <ResultsMessage>
          {"You've reached the end of the results"}
        </ResultsMessage>
      )}
      {noResults && !isError && (
        <ResultsMessage>
          {"We couldn't find any results in this period"}
        </ResultsMessage>
      )}
      {isError && (
        <div className={classes.RunHistoryDataGrid__ErrorContainer}>
          <ErrorMessage>
            Something went wrong when attempting to load the pipeline.
          </ErrorMessage>
        </div>
      )}
    </>
  )
}

export default RunHistoryDataGrid
