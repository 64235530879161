import { Typography } from '@matillion/component-library'
import { useGetStepsInfinite } from 'api/hooks/useGetSteps'
import { CenteredSpinner } from 'components/CenteredSpinner'
import ErrorMessage from 'components/ErrorMessage'
import { InfiniteScrollAnchor } from 'components/InfiniteScrollAnchor'
import { TBody, THead, Table } from 'components/DataTable'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { PipelineStatus } from 'types/eos'
import { PipelineTreeHeadRow } from '../components/PipelineTreeHeadRow'
import StepRow from '../components/StepRow'
import classes from './PipelineTree.module.scss'
import { safeString } from 'utils/safeString'

interface PipelineTreeProps {
  pipelineName: string
  title: string
  endOfResultsMessage: string
  status?: PipelineStatus
  'data-testid'?: string
}

const PipelineTree: FC<PipelineTreeProps> = ({
  pipelineName,
  status,
  endOfResultsMessage,
  title,
  'data-testid': testId
}) => {
  const { pipelineId } = useParams()

  const { steps, isFetching, isError, hasMore, fetchNextPage } =
    useGetStepsInfinite(safeString(pipelineId), status)

  if (isFetching) {
    return (
      <>
        <Typography format="ts" className={classes.PipelineTree__Title}>
          {title}
        </Typography>
        <CenteredSpinner />
      </>
    )
  }

  if (isError) {
    return (
      <>
        <Typography format="ts" className={classes.PipelineTree__Title}>
          {title}
        </Typography>
        <ErrorMessage>
          Something went wrong when attempting to load the pipeline.
        </ErrorMessage>
      </>
    )
  }

  return (
    <>
      {steps && steps?.length > 0 && (
        <>
          <Typography format="ts" className={classes.PipelineTree__Title}>
            {title}
          </Typography>
          <Table data-testid={testId}>
            <THead sticky>
              <PipelineTreeHeadRow
                cellClassName={classes.PipelineTree__Cell}
                className={classes.PipelineTree__Row}
              />
            </THead>
            <TBody>
              {steps?.map((step, index) => (
                <StepRow
                  cellClassName={classes.PipelineTree__Cell}
                  rowClassName={classes.PipelineTree__Row}
                  step={step}
                  depth={0}
                  pipelineName={pipelineName}
                  key={step.id}
                  nestedFilterStatus={status}
                  isRoot={true}
                  isLast={index === steps.length - 1}
                  treeLines={[]}
                />
              ))}
            </TBody>
          </Table>

          {hasMore && <InfiniteScrollAnchor onIntersect={fetchNextPage} />}
          {!isFetching && !hasMore && !isError && (
            <div className={classes.PipelineTree__EndOfResults}>
              <Typography type="bcl">{endOfResultsMessage}</Typography>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default PipelineTree
