import {
  Grid,
  LoadingSpinner,
  Page,
  Section,
  Toaster
} from '@matillion/component-library'
import { useGetSinglePipelineActivity } from 'api/hooks/useGetSinglePipelineActivity'
import { useNavigate, useParams } from 'react-router-dom'
import { safeString } from 'utils/safeString'
import classes from './PipelineRunDetails.module.scss'
import PipelineRunDetailsHeader from './PipelineRunDetailsHeader/PipelineRunDetailsHeader'
import PipelineTree from './PipelineTree/PipelineTree'
import { useEffect } from 'react'

const PipelineRunDetails = () => {
  const { pipelineId } = useParams()
  const navigate = useNavigate()
  const toaster = Toaster.useToaster()
  const { data, error, isLoading } = useGetSinglePipelineActivity(
    safeString(pipelineId)
  )
  useEffect(() => {
    if (!error) {
      return
    }

    navigate('/')

    if (error?.response?.status === 404) {
      toaster.makeToast({
        type: 'error',
        theme: 'dark',
        title: 'Sorry.',
        message: 'We couldn’t find that pipeline.'
      })
      return
    }

    if (error?.response?.status === 403) {
      toaster.makeToast({
        type: 'error',
        theme: 'dark',
        title: 'Sorry.',
        message: 'You don’t have permission to view that pipeline.'
      })
      return
    }

    toaster.makeToast({
      type: 'error',
      theme: 'dark',
      title: 'Sorry.',
      message: 'Something went wrong when attempting to load the pipeline.'
    })
  }, [error, navigate, toaster])

  return (
    <Page>
      <Grid className={classes.Grid}>
        {isLoading || !data ? (
          <LoadingSpinner className={classes.LoadingSpinner} />
        ) : (
          <>
            <Section size={12}>
              <PipelineRunDetailsHeader pipelineActivity={data} />
            </Section>
            <Section>
              <PipelineTree
                pipelineName={safeString(data?.definition.pipelineName)}
                title="Steps with errors"
                status="FAILED"
                endOfResultsMessage="That's all the failed steps."
                data-testid="list-failed-steps"
              />
            </Section>
            <Section size={12}>
              <PipelineTree
                pipelineName={safeString(data?.definition.pipelineName)}
                title="All steps"
                endOfResultsMessage="That's everything for this pipeline."
                data-testid="list-all-steps"
              />
            </Section>
          </>
        )}
      </Grid>
    </Page>
  )
}

export default PipelineRunDetails
