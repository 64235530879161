import { Icon } from '@matillion/component-library'

import { ReactComponent as CancelledSvg } from 'assets/cancelled-small.svg'
import { ReactComponent as ErrorSvg } from 'assets/failed-small.svg'
import { ReactComponent as SuccessSvg } from 'assets/success-small.svg'
import { PipelineStatus } from 'types/eos'

const StateIconMapper = {
  FAILED: ErrorSvg,
  SUCCESS: SuccessSvg,
  RUNNING: Icon.Loading,
  CANCELLING: CancelledSvg,
  CANCELLED: CancelledSvg,
  UNKNOWN: Icon.Warning
}

export const getStateIcon = (status: PipelineStatus | undefined) =>
  status && StateIconMapper[status] ? StateIconMapper[status] : Icon.Warning
