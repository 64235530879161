import { useCallback } from 'react'
import { JobStatus } from 'types/jobs'

export const statusMap: Record<JobStatus, string> = {
  SUCCESS: 'Success',
  FAILED: 'Failed',
  RUNNING: 'Running',
  UNKNOWN: 'Unknown',
  CANCELLING: 'Cancelling',
  CANCELLED: 'Cancelled'
}

export const useStatusTranslation = () => {
  return useCallback((status: JobStatus) => statusMap[status], [])
}
