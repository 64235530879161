import { useInfiniteQuery } from '@tanstack/react-query'
import { ActivityResponse, getActivity } from 'api/eos'
import { AxiosError } from 'axios'
import { useFilters } from 'context/FilterContext'
import { makeISODatesFromFilters } from 'context/FilterContext/makeISODatesFromFilters'
import { useMemo } from 'react'
import useEventCallback from 'use-event-callback'
import useEosClient from './useEosClient'

const getNextPage = (lastPage: Pick<ActivityResponse, 'earlierToken'>) =>
  lastPage.earlierToken

export const useGetActivity = () => {
  const client = useEosClient()
  const { filters, queryKey } = useFilters()
  const { search, status } = filters

  const { data, fetchNextPage, isFetching, isFetchingNextPage, isError } =
    useInfiniteQuery<ActivityResponse, AxiosError>({
      queryKey,
      queryFn: async ({ pageParam }) => {
        return getActivity(client, {
          search,
          status,
          ...makeISODatesFromFilters(filters),
          paginationToken: pageParam as string
        })
      },
      initialPageParam: undefined,
      getNextPageParam: getNextPage,
      refetchOnWindowFocus: false
    })

  const allActivity = useMemo(
    () => data?.pages.flatMap((page) => page.results),
    [data?.pages]
  )

  const hasEarlier = Boolean(data?.pages[data.pages.length - 1].hasEarlier)

  const fetchNext = useEventCallback(() => {
    if (hasEarlier && !isFetching) {
      void fetchNextPage()
    }
  })

  return {
    activity: allActivity,
    isFetching,
    isRefreshing: isFetching && !isFetchingNextPage,
    isPaginating: isFetchingNextPage,
    hasEarlier,
    isError,
    fetchNextPage: fetchNext
  }
}
