import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import useEosClient from './useEosClient'
import { getSinglePipelineActivity } from 'api/eos'
import { ActivityItem } from 'types/eos'
import { AxiosError } from 'axios'

export const useGetSinglePipelineActivity = (
  pipelineId: string,
  options?: UseQueryOptions<ActivityItem, AxiosError>
) => {
  const client = useEosClient()
  return useQuery<ActivityItem, AxiosError>({
    queryKey: ['singlePipelineActivity', pipelineId],
    queryFn: async () => getSinglePipelineActivity(client, pipelineId),
    ...options
  })
}
