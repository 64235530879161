import { AppMenu, AppMenuItem, Icon } from '@matillion/component-library'
import { billingURLPaths, useMatillionServiceUrl } from 'hooks'
import { useCanAccessLineage } from 'hooks/useCanAccessLineage'
import { Link, useLocation } from 'react-router-dom'
import LineageIcon from 'svg/LineageIcon'
import classes from './OdbAppMenu.module.scss'

const OdbAppMenu = () => {
  const { billingUrl } = useMatillionServiceUrl()
  const location = useLocation()
  const { canAccessLineage } = useCanAccessLineage()

  return (
    <AppMenu>
      <AppMenuItem
        as="a"
        href={billingUrl + billingURLPaths.usage}
        name="Usage"
        icon={<Icon.World />}
      />
      <AppMenuItem
        as="a"
        href={billingUrl + billingURLPaths.instances}
        name="Instances"
        icon={<Icon.Schema />}
      />
      <Link to="/">
        <AppMenuItem
          className={classes.AppMenu__Item}
          active={!location.pathname.startsWith('/lineage')}
          name="Activity"
          icon={<Icon.Graph />}
        />
      </Link>
      {canAccessLineage ? (
        <div className={classes.AppMenu__LineageContainer}>
          <Link to="/lineage">
            <AppMenuItem
              className={classes.AppMenu__Item}
              active={location.pathname.startsWith('/lineage')}
              name="Lineage"
              icon={<LineageIcon className={classes.AppMenu__LineageIcon} />}
            />
          </Link>
        </div>
      ) : (
        <></>
      )}
    </AppMenu>
  )
}

export default OdbAppMenu
