import Detail from './Detail'
import classes from './PipelineDetails.module.scss'
import { ActivityItem, DpcEtlPipelineDefinition } from 'types/eos'
import { FC } from 'react'
import { calcDuration } from 'modules/Activity/RunHistoryDataGrid/hooks/calcDuration'
import { safeString } from 'utils/safeString'
import { DateTime } from 'components/DateTime'

interface Props {
  pipelineActivity: ActivityItem
}

const PipelineDetails: FC<Props> = ({ pipelineActivity }) => {
  const {
    result: { startedAt, finishedAt },
    context
  } = pipelineActivity

  const definition = pipelineActivity.definition as DpcEtlPipelineDefinition

  return (
    <div className={classes.PipelineDetails}>
      <div className={classes.DetailsRow}>
        <Detail name="Project" value={definition.projectName} />
        <Detail
          name="Started"
          value={startedAt}
          contents={<DateTime timestamp={startedAt} />}
        />
        <Detail
          name="Completed"
          value={safeString(finishedAt)}
          contents={<DateTime timestamp={finishedAt} />}
        />
      </div>
      <div className={classes.DetailsRow}>
        <Detail
          name="Source"
          value={definition.type === 'DPC_ETL' ? 'Designer' : 'Matillion ETL'}
        />
        {context.type === 'DPC' && (
          <Detail name="Environment" value={context.environment} />
        )}
        <Detail
          name="Duration"
          value={calcDuration(startedAt, safeString(finishedAt))}
        />
      </div>
    </div>
  )
}

export default PipelineDetails
